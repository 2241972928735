<template>
  <div class="student_form_warp">
    <p>{{text}}学员信息</p>
    <el-form :model="form"
             :rules="rules"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="手机号"
                        prop="verifiedMobile">
            <el-input placeholder="请输入手机号"
                      v-model="form.verifiedMobile"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="真实姓名"
                        prop="truename">
            <el-input placeholder="请输入真实姓名"
                      v-model="form.truename"
                      style="width:100%" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="别名">
            <el-input placeholder="请输入别名"
                      v-model="form.nickname"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="毕业年份"
                        prop="graduate_year">
            <el-date-picker v-model="form.graduate_year"
                            value-format="yyyy"
                            type="year"
                            style="width:100%"
                            placeholder="请选择毕业年份">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8"
                v-if="!form.id">
          <el-form-item label="密码"
                        prop="password">
            <el-input placeholder="请输入密码"
                      type="password"
                      v-model="form.password"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="8"
                v-if="!form.id">
          <el-form-item label="重输密码"
                        prop="password1">
            <el-input placeholder="请重输密码"
                      type="password"
                      v-model="form.password1"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所在学校"
                        prop="edu_school_id">
            <el-select v-model="form.edu_school_id"
                       filterable
                       @change="getClass"
                       style="width:100%"
                       placeholder="请选择所在学校">
              <el-option v-for="item in schoolList"
                         :key="item.school_id"
                         :label="item.school_name"
                         :value="item.school_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="当前年级"
                        prop="edu_grade_id">
            <el-select v-model="form.edu_grade_id"
                       style="width:100%"
                       @change="getClass"
                       placeholder="请选择年级">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="班级"
                        prop="edu_class_id">
            <el-select v-model="form.edu_class_id"
                       style="width:100%"
                       filterable
                       placeholder="请选择班级">
              <el-option v-for="item in classList"
                         :key="item.id"
                         :label="item.class_name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学员类型">
            <el-select v-model="form.user_type_id"
                       style="width:100%"
                       filterable
                       clearable
                       placeholder="请学员类型">
              <el-option v-for="item in stuTypeList"
                         :key="item.user_type_id"
                         :label="item.user_type_name"
                         :value="item.user_type_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷地区"
                        prop="paper_district">
            <el-select v-model="form.paper_district"
                       style="width:100%"
                       @change="getDefaultCity"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in dirList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_name"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="城市"
                        prop="city_id">
            <div class="zhishidian">
              <el-cascader v-model="form.city_id"
                           :options="options"
                           filterable
                           :props="props"
                           style="width:100%"
                           @change="handleChange"></el-cascader>
            </div>
          </el-form-item>
        </el-col>

      </el-row>

    </el-form>

    <el-row :gutter="10">
      <el-col :span="24">
        <el-button type="primary"
                   @click="save">确认</el-button>
      </el-col>
    </el-row>

    <AppForm ref="AppFormRef"
             v-if="form.id" />
  </div>
</template>

<script>
import AppForm from './appForm.vue'
import { getCityTree, getDefaultCityByDis } from '@/api/city.js'
export default {
  components: {
    AppForm,
  },
  data () {
    var checkPwd = (rule, value, callback) => {
      if (this.form.password1 != this.form.password) {
        return callback(new Error('两次密码不一致'));
      } else {
        return callback()
      }
    };
    return {
      options: [],
      props: { value: 'city_id', label: 'name', children: 'child' },
      form: {
        verifiedMobile: '',
        truename: '',
        nickname: '',
        password: '',
        password1: '',
        graduate_year: '',
        edu_grade_id: '',
        edu_class_id: '',
        edu_school_id: '',
        correct_type: 2,
        user_type_id: 1,
        end_time: '',
        paper_district: 1,
        check_paper: 0
      },
      rules: {
        truename: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        verifiedMobile: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        edu_grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_school_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_class_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ],
        password1: [
          { validator: checkPwd, trigger: 'blur', required: true }
        ],
        graduate_year: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        city_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_district: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      value: '',
      options: [],
      activeName: '初三',
      checkList: [],
      subjectList: [],
      gradeList: [],
      schoolList: [],
      stuTypeList: [],
      dirList: [],
      tallOrfrist: [
        { value: 1, label: '高中' },
        { value: 0, label: '初中' },
      ],
      text: '新增',
      classList: [],
      childList: []
    }
  },
  mounted () {
    this.getList()
    // 因为都是异步请求 所以在获取到地区树形结构之后获取用户信息
    this.getCityTree()

  },
  methods: {
    handleChange (e) {
      console.log('e', e)
    },
    getClass () {
      this.form.edu_class_id = ''
      if (this.form.edu_school_id == '' || this.form.edu_grade_id == '') return
      this.getClassList()
    },
    async getDefaultCity () {
      const { data } = await getDefaultCityByDis({ paper_district_id: this.form.paper_district })
      // this.form.city_id = data
      this.$set(this.form, 'city_id', data)
      // console.log(this.form.city_id)
      this.$forceUpdate()

    },
    getClassList () {
      this.$http({
        url: '/api/v2/public/class_sg',
        method: 'get',
        params: {
          school_id: this.form.edu_school_id,
          grade_id: this.form.edu_grade_id
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    async getCityTree () {
      const { data } = await getCityTree()
      this.options = data
      this.options.forEach(item => {
        if (item.child) {
          this.childList.push(...item.child)
        }
      });
      if (this.$route.query.id) {
        this.text = '修改'
        this.getInfo(this.$route.query.id)
      } else {
        this.getDefaultCity()
      }
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        if (form.id) {
          delete form.password
          delete form.password1
        }
        if (form.city_id) {
          form.city_id = form.city_id[1]
          form.city_cn = this.childList.find(item => item.city_id == form.city_id).name
        }
        this.$http({
          url: '/api/v2/student/create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.getInfo(res.data.user_id)
          this.$router.push({ query: { ...this.$route.query, id: user_id } });
        })
      })
    },
    getInfo (user_id) {
      this.$http({
        url: '/api/v2/student/info',
        method: 'get',
        params: {
          user_id
        }
      }).then(res => {
        this.text = '修改'
        res.data.graduate_year += ''
        res.data.paper_district = res.data.paper_district_id
        this.form = this.getParent(res.data)
        this.getClassList()
        this.getddupInfo()
      })
    },
    getParent (form) {
      if (form.city_id) {
        let info = this.childList.find(item => item.city_id == form.city_id)
        form.city_id = [info.parent_id, info.city_id]
      }
      return form
    },
    getddupInfo () {
      this.$http({
        url: '/api/v2/student/app_info',
        method: 'get',
        params: {
          user_id: this.form.id,
          app_key: 'daydayup'
        }
      }).then(res => {
        this.$refs.AppFormRef.setList(res.data)
      })
    },
    getczbInfo () {
      this.$http({
        url: '/api/v2/student/app_info',
        method: 'get',
        params: {
          user_id: this.form.id,
          app_key: 'czb'
        }
      }).then(res => {
        this.$refs.beikaoRef.setList(res.data)
      })
    },
    getList () {
      // 获取学校
      this.$http({
        url: '/api/v2/public/enabled_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
      })

      // 获取学员类型
      this.$http({
        url: '/api/v2/public/user_type',
        method: 'get'
      }).then(res => {
        this.stuTypeList = res.data
      })

      // 获取试卷地区
      this.$http({
        url: '/api/v2/public/paper_district',
        method: 'get'
      }).then(res => {
        this.dirList = res.data.list
      })

      this.$http({
        url: '/api/v2/public/subject_new',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data
      })
    },
    checkRadio () {
      if (this.form.check_paper == 0) {
        this.form.check_paper = 1
        return
      }
      this.form.check_paper = 0
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.hidden_radio {
  ::v-deep .el-radio__label {
    display: none;
  }
}

// ::v-deep .el-checkbox {
//   width: 100px;
//   margin-right: 25px;
//   &:nth-child(10) {
//     width: 80px;
//   }
// }
::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #409eff;
}
.item_warp {
  ::v-deep .el-form-item__label {
    line-height: 20px;
  }
}

p {
  line-height: 40px;
  font-weight: bold;
}
.student_form_warp {
}
</style>