<template>
  <div>
    <el-dialog title="批量修改科目时间"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="100px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="年级"
                          prop="grade_id">
              <el-select v-model="form.grade_id"
                         style="width:100%"
                         placeholder="请选择年级">
                <el-option v-for="item in $parent.gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="截止时间"
                          prop="end_time">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              default-time="23:59:59"
                              placeholder="请选择截止时间 " />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        end_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.ids = []
        this.$parent.allData.forEach(item => {
          if (item.grade_id == form.grade_id) {
            form.ids.push(item.id)
          }
        })
        form.ids = form.ids.join(',')

        this.$http({
          url: '/api/v2/student/edit_times',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.$parent.getAppInfo()
          this.handleClose()
        })
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.time_warp {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 33%;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.subject {
  display: flex;
  margin: 5px 0;
  div {
    &:nth-child(1) {
      width: 90px;
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
  }
}
</style>