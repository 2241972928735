<template>
  <div class="app_warp">
    <div class="shadow_line"></div>
    <p>
      智能学伴APP
    </p>
    <el-form label-width="90px"
             ref="formRef"
             :model="form"
             :rules="rules">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="改卷类型"
                        label-width="110px">
            <el-radio-group v-model="form.correct_type">
              <el-radio :label="1">平台改卷</el-radio>
              <el-radio :label="2">学生自评</el-radio>
              <el-radio :label="3">本校改卷</el-radio>
              <el-radio :label="4">本校改卷(释放平台)</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="是否显示答疑"
                        label-width="110px">
            <el-radio-group v-model="form.discuss">
              <el-radio :label="1">显示答疑</el-radio>
              <el-radio :label="0">不显示答疑</el-radio>

            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="截止时间"
                        prop="end_time">
            <el-date-picker v-model="form.end_time"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetime"
                            default-time="23:59:59"
                            placeholder="请选择截止时间 " />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <div class="toopic">
            <span>
              注:各种非学员(测试账号、供应商账号等)默认为一直使用
            </span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="radio_warp">
            <el-radio v-model="radio"
                      @click.native.prevent="checkAll"
                      :label="1">全选科目</el-radio>
            <el-button type="primary"
                       v-if="allData.length"
                       @click="editTimes">
              批量修改
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="grade.grade_name"
                       v-for="grade in gradeList"
                       :key="grade.grade_name"
                       :name="grade.grade_name">
            <el-checkbox-group v-model="grade.subjects">
              <el-checkbox :label="subject.subject_id"
                           v-for="subject in grade.subjectList"
                           :key="subject.subject_id">{{subject.subject_name}}</el-checkbox>
            </el-checkbox-group>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-form>

    <el-table border
              stripe
              v-if="tableData.length"
              :data="tableData"
              highlight-current-row
              style="width: 100%;margin-top:20px">
      <el-table-column prop="subject_name"
                       align="center"
                       label="科目" />
      <el-table-column prop="grade_name"
                       align="center"
                       label="当前年级" />
      <el-table-column prop="class_name"
                       align="center"
                       label="班级名称" />
      <el-table-column prop="mode_name"
                       align="center"
                       label="模式" />
      <el-table-column prop="textbook_name"
                       align="center"
                       label="教材" />
      <el-table-column prop="end_time"
                       align="center"
                       label="截止时间" />
      <el-table-column align="center"
                       width="200"
                       label="操作">
        <template slot-scope="{row}">
          <div @click="editTime(row)"
               class="text_btn_primary">修改</div>
        </template>
      </el-table-column>
    </el-table>

    <el-row :gutter="10"
            style="margin:10px 0">
      <el-col :span="12">
        <el-button type="primary"
                   @click="save">
          确定
        </el-button>
      </el-col>
    </el-row>
    <editEndtimeForm ref="editEndtimeFormRef" />
    <editTimes ref="editTimesRef" />

  </div>
</template>

<script>
import editEndtimeForm from './editEndTime.vue'
import editTimes from './editTimes.vue'
export default {
  data () {
    return {
      form: {
        correct_type: 2,
        end_time: '',
        end_time: '',
        discuss: 1,
        grade_subject_map: []
      },
      allData: [],
      tableData: [],
      gradeList: [],
      activeName: '',
      rules: {
        end_time: [
          { required: true, message: '请选择', trigger: 'blur' },
        ]
      },
      radio: 0
    }
  },
  components: {
    editEndtimeForm, editTimes
  },
  watch: {
    activeName () {
      this.tableData = this.allData.filter(item => item.grade_name == this.activeName)
      this.changeStatus()
    },
    gradeList: {
      handler () {
        this.changeStatus()
      },
      deep: true
    }
  },
  mounted () {
    this.getGradeList()
  },
  methods: {
    changeStatus () {
      var grade = this.gradeList.find(item => item.grade_name == this.activeName)
      if (!grade) return
      if (this.activeName == '初一' && grade.subjects.length == 7) {
        this.radio = 1
        return
      }
      if (this.activeName == '初三' && grade.subjects.length == 7) {
        this.radio = 1
        return
      }
      if (this.activeName == '初二' && grade.subjects.length == 8) {
        this.radio = 1
        return
      }
      this.radio = 0
    },
    checkAll () {
      if (!this.radio) {
        this.radio = 1
        var grade = this.gradeList.find(item => item.grade_name == this.activeName)
        grade.subjects = grade.subjectList.map(item => item.subject_id)
      } else {
        var grade = this.gradeList.find(item => item.grade_name == this.activeName)
        grade.subjects = []
        this.radio = 0
      }
    },
    getGradeList (list) {
      this.activeName = ''
      this.$http({
        url: '/api/v2/public/subject_new',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.map(item => {
          item.subjectList = [...Object.values(JSON.parse(JSON.stringify(item.subjects)))]
          item.subjects = []
          if (list) {
            list.forEach(ele => {
              if (ele.grade_id == item.grade_id) {
                item.subjects.push(ele.subject_id)
              }
            })
          }
          return item
        })
        this.activeName = '初一'
      })
    },
    setList (data) {
      console.log(data)
      this.allData = JSON.parse(JSON.stringify(data.grade_subject_map))
      this.tableData = this.allData.filter(item => item.grade_name == this.activeName)

      if (data.appInfo) {
        this.form.correct_type = data.appInfo.correct_type
        this.form.end_time = data.appInfo.end_time
        this.form.discuss = data.appInfo.discuss
      }
      this.getGradeList(data.grade_subject_map)
      // }
    },
    save () {
      /**
       *['id', 0],
        ['app_id', ''],
        ['correct_type', ''],
        ['end_time', ''],
        ['grade_subject_map', '']
       */
      this.$refs.formRef.validate(val => {
        if (!val) return
        let copyForm = JSON.parse(JSON.stringify(this.form))
        let grade_subject_map = this.gradeList.map(item => {
          var obj = {
            grade_id: item.grade_id,
            end_time: copyForm.end_time,
            subjects: item.subjects
          }
          return obj
        })
        let id = this.$parent.form.id
        let data = {
          id,
          app_key: 'daydayup',
          end_time: copyForm.end_time,
          correct_type: copyForm.correct_type,
          grade_subject_map,
          discuss: copyForm.discuss
        }
        this.$http({
          url: '/api/v2/student/add_app',
          method: 'post',
          data
        }).then(res => {
          this.$message.success('成功')
          this.$parent.getddupInfo()
        })
      })
    },
    editTime (row) {
      let form = {
        id: row.id,
        end_time: row.end_time,
        subject_name: row.subject_name,
        textbook_id: row.textbook_id ? row.textbook_id : ''
      }
      this.$refs.editEndtimeFormRef.form = form
      this.$refs.editEndtimeFormRef.dialogVisible = true
      this.$refs.editEndtimeFormRef.getBookBySubject(row.subject_id)
    },
    editTimes () {
      let form = {
        id: this.$route.query.id,
        ids: '',
        end_time: '',
      }
      this.$refs.editTimesRef.form = form
      this.$refs.editTimesRef.dialogVisible = true
    },
    getAppInfo () {
      this.$parent.getddupInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.app_warp {
  p {
    line-height: 40px;
    font-weight: bold;
    margin-top: 5px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 5px;
  }
}

.toopic {
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  margin-left: -15px;
  span {
    display: inline-block;
    color: #ff8383;
    transform: scale(0.9);
  }
}

.shadow_line {
  width: calc(100% + 30px);
  background: #f6f6f6;
  margin-left: -15px;
  height: 20px;
  margin-top: 20px;
}
.radio_warp {
  line-height: 36px;
}
</style>