<template>
  <div>
    <el-dialog title="修改科目时间"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               :rules="rules"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item :label="form.subject_name"
                          prop="end_time">
              <el-date-picker v-model="form.end_time"
                              type="datetime"
                              style="width:100%"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="教材"
                          prop="textbook_id">
              <el-select v-model="form.textbook_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in textbookList"
                           :key="item.textbook_id"
                           :label="item.textbook_name"
                           :value="item.textbook_id" />
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      textbookList: [],
      timers: [],
      form: {},
      rules: {
        end_time: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      },

    }
  },
  methods: {
    handleClose () {
      this.textbookList = []
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        delete form.subject_name
        this.$http({
          url: '/api/v2/student/edit_buy_subject',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.save()
        })
      })
    },
    getBookBySubject (id) {
      this.$http({
        url: '/api/v2/public/getBookBySubject',
        method: 'get',
        params: {
          subject_id: id
        }
      }).then(res => {
        this.textbookList = res.data.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.time_warp {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 33%;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.subject {
  display: flex;
  margin: 5px 0;
  div {
    &:nth-child(1) {
      width: 90px;
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
  }
}
</style>